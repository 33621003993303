import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button, Tabs, Tab } from "@themesberg/react-bootstrap";
import { Carousel } from "react-bootstrap";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"; 
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Link } from 'react-router-dom';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import "../style/clients.css";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Mostrar{' '}
        <select
          name="datatable-basic_length"
          aria-controls="datatable-basic"
          className="form-control form-control-sm"
          onChange={(e) => onSizePerPageChange(e.target.value)}
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>{' '}
        registros
      </label>
    </div>
  ),
});

const categories = [
  { id: 1, name: 'Driver', image: 'https://rccolombiangolf.com/wp-content/uploads/2022/02/EPIC-MAX-LS.png' },
  { id: 2, name: 'Madera', image: 'https://rccolombiangolf.com/wp-content/uploads/2021/12/GLE-MADERA.jpg' },
  { id: 3, name: 'Hibrido', image: 'https://rccolombiangolf.com/wp-content/uploads/2022/02/G425.jpg' },
  { id: 4, name: 'Hierros', image: 'https://rccolombiangolf.com/wp-content/uploads/2022/02/P770-IRON.jpg' },
  { id: 5, name: 'Wedges', image: 'https://rccolombiangolf.com/wp-content/uploads/2021/12/MILLED-GRIND-3.jpg' },
  { id: 6, name: 'Iron Driving', image: 'https://rccolombiangolf.com/wp-content/uploads/2024/10/driving-iron.webp' },
  { id: 37, name: 'Putts', image: 'https://rccolombiangolf.com/wp-content/uploads/2021/12/HEPPLER-TOMCAT-14.jpg' },
];

const chunkCategories = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

const CategorySlider = () => {
  const [idFitting, setIdFitting] = useState([]);
  const navigate = useNavigate();
  const storedIdClient = localStorage.getItem('id');

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/informe-tecnico-cliente`, {
          params: { idClient: storedIdClient }
        });

        if (response.data && response.data.length > 0) {
          setIdFitting(response.data[0].id); 
        } else {
          console.error("No data received");
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchProducts();
  }, [storedIdClient]);

  const handleCategoryClick = (id) => {
    navigate(`/client/product/${id}/${idFitting}`);
  };

  const categoryChunks = chunkCategories(categories, 4);

  return (
    <Carousel
      interval={5000}
      fade
      indicators={true}
      prevIcon={<FontAwesomeIcon icon={faArrowLeft} className="custom-arrow" />} 
      nextIcon={<FontAwesomeIcon icon={faArrowRight} className="custom-arrow" />}
      className="custom-carousel"
    >
      {categoryChunks.map((categoryChunk, index) => (
        <Carousel.Item key={index}>
          <Row className="justify-content-center">
            {categoryChunk.map((category) => (
              <Col md={3} key={category.id}>
                <Card className="text-center category-card" onClick={() => handleCategoryClick(category.id)}>
                  <Card.Img variant="top" src={category.image} alt={category.name} className="category-image" />
                  <Card.Body>
                    <Card.Title className="category-title">{category.name}</Card.Title>
                    <Button variant="primary" className="category-button">Ver</Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

const Fitting = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const storedIdClient = localStorage.getItem('id');
    if (storedIdClient) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/informes-tecnicos-clientes`, {
          params: { idClient: storedIdClient },
        })
        .then((response) => {
          if (response.data !== 'vacio') {
            setData(response.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const { SearchBar } = Search;

  return (
    <ToolkitProvider
      data={data}
      keyField="id"
      columns={[
        { dataField: 'name', text: 'Nombre', sort: true },
        { 
          dataField: 'created_at', 
          text: 'Fecha', 
          sort: true,
          formatter: (cell) => new Date(cell).toLocaleDateString(),
        },
        { 
          dataField: 'id', 
          text: 'Acción',
          formatter: (cellContent, row) => (
            <Link to={`/informe-pdf/${row.id}`}>
              <button type="button" className='btn btn-warning btn-sm'>Ver informe PDF</button>
            </Link>
          ),
        },
      ]}
      search
    >
      {(props) => (
        <div className="table_class py-4">
          <div className="dataTables_filter px-1 pb-4">
            <label>
              Buscar:
              <SearchBar className="form-control-sm" {...props.searchProps} />
            </label>
          </div>
          {data.length > 0 ? (
            <BootstrapTable {...props.baseProps} bootstrap4 pagination={pagination} bordered={false} />
          ) : (
            <p>No hay datos disponibles</p>
          )}
        </div>
      )}
    </ToolkitProvider>
  );
};

export default () => {
  const [activeTab, setActiveTab] = useState("product");

  return (
    <Container>
      <h1 className="text-center display-4 mb-4">Categoría de producto</h1>
      <Tabs id="product-tabs" activeKey={activeTab} onSelect={setActiveTab} className="mb-3">
        <Tab eventKey="product" title="Productos">
          <CategorySlider />
        </Tab>
        <Tab eventKey="fitting" title="Fitting">
          <Fitting />
        </Tab>
      </Tabs>
    </Container>
  );
};
