import React, { useContext , useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Nav, Dropdown, Container, Image } from '@themesberg/react-bootstrap';
import { AuthContext } from "./AuthContext";

import Profile3 from "../assets/img/team/profile-picture-3.jpg";

export default () => {
  const [userName, setUserName] = useState("");
 
  useEffect(() => {
    const storedName = localStorage.getItem("nombres");
    if (storedName) {
      setUserName(storedName);
    }
  }, []);

  const { logout } = useContext(AuthContext);

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <Nav className="ms-auto align-items-center"> {/* Alineación a la derecha */}
          <Dropdown as={Nav.Item}>
            <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
              <div className="media d-flex align-items-center">
                <Image src={Profile3} className="user-avatar md-avatar rounded-circle" />
                <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                  <span className="mb-0 font-small fw-bold">{userName}</span>
                </div>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
              <Dropdown.Item className="fw-bold text-danger" onClick={logout}>
                <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Cerrar sesión
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Container>
    </Navbar>
  );
};
