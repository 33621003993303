import React, { useContext } from "react";
import Login from "../pages/Signin";
import Home from "../pages/HomePage";
import { AuthContext } from "./AuthContext"

const Main = () => {
  const { isAuthenticated } = useContext(AuthContext);

  return isAuthenticated ? <Home /> : <Login />;
};

export default Main;
