import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import "./scss/volt.scss";
import "react-datetime/css/react-datetime.css";
import "../src/assets/css/style.css";
import ScrollToTop from "./components/ScrollToTop";
import Validation from "./components/validation";
import { AuthProvider } from "./components/AuthContext";

ReactDOM.render(
  <AuthProvider> 
    <HashRouter>
      <ScrollToTop />
      <Validation />
    </HashRouter>
  </AuthProvider>,
  document.getElementById("root")
);
