import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { RoutesPage } from '../routes';
import ProtectedRouteWithSidebar from './ProtectedRoute';

// Pages
import Upgrade from './Upgrade';
import Home from './dashboard/home';
import HomeClient from './clients/home';
import productClient from './clients/product';
import FittingP from './FittingP';
import FittingClient from './clients/FittingClient';
import Varas from './varas';
import ModeloVarilla from './ModeloVarilla';
import ModeloDriver from './ModeloDriver';
import MdcPalosLargos from './MdcPalosLargos';
import MdcHierros from './MdcHierros';
import MdcIronDriving from './MdcIronDriving';
import LoftDriver from './LoftDriver';
import LoftHierros from './LoftHierros';
import MdcPutts from './MdcPutts';
import TipoCabeza from './TipoCabeza';
import TipoPalo from './TipoPalo';
import FabricaVara from './FabricaVara';
import Flexes from './Flexes';
import Marcas from './Marcas';
import Bolas from './Bolas';
import Wedges from './Wedges';
import Alcances from './Alcances';
import Clubs from './Clubs';
import GripsMedidas from './GripsMedidas';
import GripsReferencia from './GripsReferencia';
import Handicaps from './Handicaps';
import Pulgadas from './Pulgadas';
import LongVara from './LongVara';
import Settings from './Settings';
import BootstrapTables from './tables/BootstrapTables';
import ForgotPassword from './examples/ForgotPassword';
import ResetPassword from './examples/ResetPassword';
import Lock from './examples/Lock';
import NotFoundPage from './examples/NotFound';
import ServerError from './examples/ServerError';

// Documentation pages
import InformePdf from './informePdf';

// Components page
import Preloader from '../components/Preloader';

import FittingDetails from './components/Fitting/FittingDetails';
import FittingDetailsNew from './components/Fitting/FittingDetailsNew';
import LoftHierrosNew from './components/loft-hierros/LoftHierrosNew';
import LoftHierrosDetails from './components/loft-hierros/LoftHierrosDetails';

// Components
import InformeActualizar from '../components/fitting-components/Functions/functions-actualizar-informe';

const RouteWithLoader = ({ element, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Preloader show={!loaded} />
      {React.cloneElement(element, rest)}
    </>
  );
};

// Rutas protegidas y de ejemplo
export default function AppRoutes() {
  return (
    <Routes>
      {/* Rutas públicas */}
      {/* <Route path={RoutesPage.Signup.path} element={<RouteWithLoader element={<Signup />} />} /> */}
      <Route path={RoutesPage.ForgotPassword.path} element={<RouteWithLoader element={<ForgotPassword />} />} />
      <Route path={RoutesPage.ResetPassword.path} element={<RouteWithLoader element={<ResetPassword />} />} />
      <Route path={RoutesPage.Lock.path} element={<RouteWithLoader element={<Lock />} />} />
      <Route path={RoutesPage.NotFound.path} element={<RouteWithLoader element={<NotFoundPage />} />} />
      <Route path={RoutesPage.ServerError.path} element={<RouteWithLoader element={<ServerError />} />} />
      <Route path={RoutesPage.InformePdf.path} element={<RouteWithLoader element={<InformePdf />} />} />


      {/* Rutas solo para administradores */}
      <Route path={RoutesPage.home.path} element={<ProtectedRouteWithSidebar element={Home} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.Upgrade.path} element={<ProtectedRouteWithSidebar element={Upgrade} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.Fitting.path} element={<ProtectedRouteWithSidebar element={FittingP} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.Varas.path} element={<ProtectedRouteWithSidebar element={Varas} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.ModeloVarilla.path} element={<ProtectedRouteWithSidebar element={ModeloVarilla} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.ModeloDriver.path} element={<ProtectedRouteWithSidebar element={ModeloDriver} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.MdcPalosLargos.path} element={<ProtectedRouteWithSidebar element={MdcPalosLargos} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.MdcHierros.path} element={<ProtectedRouteWithSidebar element={MdcHierros} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.MdcIronDriving.path} element={<ProtectedRouteWithSidebar element={MdcIronDriving} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.LoftDriver.path} element={<ProtectedRouteWithSidebar element={LoftDriver} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.LoftHierros.path} element={<ProtectedRouteWithSidebar element={LoftHierros} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.MdcPutts.path} element={<ProtectedRouteWithSidebar element={MdcPutts} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.TipoCabeza.path} element={<ProtectedRouteWithSidebar element={TipoCabeza} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.TipoPalo.path} element={<ProtectedRouteWithSidebar element={TipoPalo} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.FabricaVara.path} element={<ProtectedRouteWithSidebar element={FabricaVara} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.Alcances.path} element={<ProtectedRouteWithSidebar element={Alcances} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.Clubs.path} element={<ProtectedRouteWithSidebar element={Clubs} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.Flexes.path} element={<ProtectedRouteWithSidebar element={Flexes} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.Marcas.path} element={<ProtectedRouteWithSidebar element={Marcas} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.Bolas.path} element={<ProtectedRouteWithSidebar element={Bolas} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.Wedges.path} element={<ProtectedRouteWithSidebar element={Wedges} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.GripsMedidas.path} element={<ProtectedRouteWithSidebar element={GripsMedidas} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.GripsReferencia.path} element={<ProtectedRouteWithSidebar element={GripsReferencia} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.Handicaps.path} element={<ProtectedRouteWithSidebar element={Handicaps} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.Pulgadas.path} element={<ProtectedRouteWithSidebar element={Pulgadas} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.LongVara.path} element={<ProtectedRouteWithSidebar element={LongVara} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.Settings.path} element={<ProtectedRouteWithSidebar element={Settings} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.BootstrapTables.path} element={<ProtectedRouteWithSidebar element={BootstrapTables} allowedRoles={['admin']} />} />

      {/* Components Page */}
      <Route path={RoutesPage.FittingDetails.path} element={<ProtectedRouteWithSidebar element={FittingDetails} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.FittingDetailsNew.path} element={<ProtectedRouteWithSidebar element={FittingDetailsNew} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.LoftHierrosNew.path} element={<ProtectedRouteWithSidebar element={LoftHierrosNew} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.LoftHierrosDetails.path} element={<ProtectedRouteWithSidebar element={LoftHierrosDetails} allowedRoles={['admin']} />} />
      <Route path={RoutesPage.InformeActualizar.path} element={<ProtectedRouteWithSidebar element={InformeActualizar} allowedRoles={['admin']} />} />

      {/* Rutas solo para clientes */}
      <Route path={RoutesPage.client.path} element={<ProtectedRouteWithSidebar element={HomeClient} allowedRoles={['user']} />} />
      <Route path={RoutesPage.clientProduct.path} element={<ProtectedRouteWithSidebar element={productClient} allowedRoles={['user']} />} />
      <Route path={RoutesPage.FittingClient.path} element={<ProtectedRouteWithSidebar element={FittingClient} allowedRoles={['user']} />} />

      {/* Redirect to 404 */}
      <Route path="*" element={<Navigate to="/not-found" />} />
    </Routes>
  );
}